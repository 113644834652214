import * as React from "react";
import type { SVGProps } from "react";
const SvgTickCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      stroke="#1E40AF"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m16 8-3.37 3.763C11.297 13.254 10.629 14 9.75 14s-1.546-.746-2.88-2.237l-.87-.97M21 11c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10Z"
    />
  </svg>
);
export default SvgTickCircle;
